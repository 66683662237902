/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--20 pt--20" name={"[[UNIsecname1]]-0"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" animS={"3"} style={{"paddingLeft":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":780}}>
              
              <Title className="title-box title-box--center fs--72" content={"<font color=\"#f5f6fb\">Apartmán v bytovém domě RUSALKA </font><font color=\"#f5f6fb\"><br></font>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"jomvvsgd3pj"} style={{"backgroundColor":"#0a2ec5"}} parallax={false}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--1 flex--center" anim={"2"} animS={"8"} columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":1300}}>
              
              <Text className="text-box" content={"Rusalka Apart-Residence se nachází v destinaci Obzor a nabízí sezónní venkovní bazén a bezplatné Wi-Fi. Pláž Centralen Obzor je 200 metrů odtud a Obzor North Beach <br>900 metrů.<br>Apartmán se může pochlubit 2 balkony s výhledem na město a na moře a má klimatizaci, kabelovou TV s plochou obrazovkou a plně vybavenou kuchyň. K dispozici je také lednice, mikrovlnná trouba, varná deska, kávovar a varná konvice.<br>Action Aquapark se nachází 33 km od ubytování Rusalka Apart-Residence. Letiště Burgas je od ubytování Rusalka Apart-Residence 62 km.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-n8mmrj pb--80 pt--80" name={"[[UNIsectionname3]]"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/39506/8301a1371b3644fd89df5b0c7a27b5e3_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"[[UNIsecname7]]"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: var(--color-dominant);\">Check in a check out<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"V den příjezdu se ubytovává po 14 hodině a apartmán je třeba uvolnit v den odjezdu do 11 hodin. Předem se hradí záloha ve výši 2 nocí, doplatek při příjezdu kartou, převodem nebo v hotovosti.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: var(--color-dominant);\">Ceník<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Ceny za pronájem celého aparmánu: červenec a srpen 130 EURO noc, červen a září 90 Euro/noc, květen a říjen 60 Euro/noc, ostatní měsíce 45 Euro/noc. 15.12 - 10.1 90 Euro/noc. Při pronájmu 2 týdny a více (kromě července a srpna) sleva 10%.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<font color=\"#fbfbfc\">Délka pobytu<br></font>"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Minimální délka pobytu jsou 3 dny. Mimo červenec a srpen je možné vyjímečně po domluvě využít jen obyvací pokoj a jednu ložnici s koupelnou se slevou.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}